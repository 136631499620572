import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { CustomButton } from "./customButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    display: "flex",
    flexDirection: "column",

    "& .MuiInputBase-root": {
      marginBottom: 16,
      height: 44,
    },
    "& .MuiInputBase-input": {
      padding: "16px 12px",
    },
  },
  textBlock: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 600,
  },
  actionButton: {
    marginBottom: "8px !important",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
}));

export function Authentication({ content, handleClick }) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const confirmDropdown = (element) => {
    if (!element.data) {
      element.data = {};
    }
    if (email && password) {
      element.data["email"] = email;
      element.data["password"] = password;
    }

    handleClick(element);
  };

  const handleEmailchange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className={classes.root}>
      {content.body.map((element) => {
        return (
          <>
            <div className={classes.textBlock}>{element.label}</div>
            {element.id == "password" ? (
              <OutlinedInput
                id="outlined-adornment-password"
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            ) : (
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={handleEmailchange}
              />
            )}
          </>
        );
      })}
      {content?.actions.map((element) => {
        return (
          <CustomButton
            onClick={() => confirmDropdown(element)}
            variant={element.title == "Verify" ? "contained" : "outlined"}
            element={element}
            className={classes.actionButton}
          ></CustomButton>
        );
      })}
    </div>
  );
}

export function MFACode({ content, handleClick }) {
  const classes = useStyles();
  const [mfaCode, setMfaCode] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const confirmDropdown = (element) => {
    console.log(element);
    if (mfaCode) {
      element.data["mfaCode"] = mfaCode;
    }
    handleClick(element);
    setConfirmed(true);
  };

  const getVariant = (text) => {
    switch (text) {
      case "Confirm":
        return "contained";
      case "Resend":
        return "outlined";
      default:
        return "text";
    }
  };

  const handleMfaCodeChange = (event) => {
    setMfaCode(event.target.value);
  };
  return (
    <div className={classes.root}>
      {content.body.map((element) => {
        return (
          <>
            <div className={classes.textBlock}>{element.label}</div>
            <TextField
              id="outlined-basic"
              variant="outlined"
              onChange={handleMfaCodeChange}
            />
          </>
        );
      })}
      <div className={classes.actions}>
        {!confirmed
          ? content?.actions.map((element) => {
              return (
                <CustomButton
                  onClick={() => confirmDropdown(element)}
                  variant={getVariant(element.title)}
                  element={element}
                ></CustomButton>
              );
            })
          : null}
      </div>
    </div>
  );
}

Authentication.propTypes = {
  content: PropTypes.object,
};
