import React, { useEffect, useState } from "react";
import ReactWebChat, { createDirectLine } from "botframework-webchat";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import {
  CloseFullscreen,
  Close as CloseIcon,
  Menu as MenuIcon,
  OpenInFull,
} from "@mui/icons-material";
import CustomCard from "./CustomCard";
import { CustomButton } from "./customButton";
import { getInitialsFromFirstNameOrFullNameAndLastName } from "../helpers/strings";
const guestAvatarURL = "assets/images/Guest_avatar.svg";
const hopeChatBotAvatarURL = "assets/images/Hope_Agent_avatar_40x40.svg";
const defaultAvatarStyles = { borderRadius: "50%", width: 32, height: 32 };

const useStyles = makeStyles(() => ({
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
    "& .webchat__send-box__main": {
      padding: "16px 0 16px 44px",
    },

    "& .webchat__send-box-text-box": {
      border: "1px solid #98A8B8",
      borderRadius: 4,
    },
    "& .MuiTypography-root": {
      fontFamily: "Montserrat",
    },
    "& .MuiChip-label": {
      fontFamily: "Montserrat",
    },
  },
  chatHeader: {
    backgroundColor: "white",
    borderBottom: "none",
    overflow: "hidden",
    background: "#E4FBFA",
    height: "80px",
    borderRadius: "24px 24px 0px 0px",
    textAlign: "center",
    position: "fixed",
    top: 0,
    zIndex: 1,
    width: "100%",
  },

  accent: {
    width: "100%",
    height: 8,
    backgroundColor: "#00998F",
  },
  headerFlexContainer: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    paddingLeft: 16,
  },
  headerText: {
    marginLeft: 8,
    width: 187,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",

    "& p": {
      fontWeight: 600,
    },

    "& span": {
      color: "#536E88",
      fontSize: 12,
    },
  },
  headerIcons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: 187,
    flexGrow: 1,
    "& .MuiIconButton-root": {
      color: "#006298",
    },
  },
  disclaimer: {
    position: "fixed",
    top: 80,
    zIndex: 1,
    width: "100%",
    fontSize: 12,
    textAlign: "center",
    color: "#192129",
    background: " var(--White, #FFF)",
    display: (props) => (props.isMinimized ? "none" : "flex"),
    flexDirection: "column",
  },
  disclaimerItem: {
    borderTop: "1px solid #B9C5D1",
    padding: 16,
    margin: 0,
  },
  emergencyBanner: {
    borderBottom: "1px solid #B9C5D1",
    color: "#891511",
  },

  chatBody: {
    marginTop: 190,
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "flex-end",
    overflowY: "auto",
    display: (props) => (props.isMinimized ? "none" : "flex"),
  },
  iconButton: {
    padding: 8,
  },

  infoIcon: {
    height: "20px",
    width: "20px",
  },

  menuIcon: {
    height: "25px",
    width: "24px",
    color: "#006298",
  },
  closeModal: {
    display: "flex",
    flexDirection: "column",
    top: 80,
    height: "calc(100vh - 80px)",
    zIndex: 1,
    background: "white",
    position: "absolute",
    borderTop: "1px solid #B9C5D1",
    width: "100vw",
  },
  closeModalItem: {
    padding: 24,
    boxShadow: "0px -1px 0px 0px #DAE2EA inset",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 500,
  },
  grow: {
    flexGrow: 1,
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
  },
  actions: {
    padding: 16,
    display: "flex",
    justifyContent: "center",
    gap: 8,
  },
}));

export default function AzureChatBot() {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isChatClosed, setIsChatClosed] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const classes = useStyles({ isChatClosed, isMinimized });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [directLine, setDirectline] = useState(null);

  const styleOptions = {
    hideUploadButton: true,
    backgroundColor: "#F8FAFB",
    bubbleBackground: "#F1FAFD",
    bubbleBorderRadius: 0,
    bubbleBorderColor: "transparent",
    bubbleBorderStyle: "solid",
    bubbleBorderWidth: 1,
    bubbleTextColor: "#192129",
    bubbleNubSize: 0,
    bubbleNubOffset: "bottom",
    avatarBorderRadius: "50%",
    avatarSize: 32,
    botAvatarImage: "botAvatar.png",
    botAvatarInitials: "CA",
    userAvatarImage: "userAvatar.png",
    userAvatarInitials: "PT",
    bubbleFromUserBackground: "#075080",
    bubbleFromUserBorderColor: "#075080",
    bubbleFromUserBorderRadius: 8,
    bubbleFromUserBorderStyle: "solid",
    bubbleFromUserBorderWidth: 1,
    bubbleFromUserNubOffset: 0,
    bubbleFromUserNubSize: undefined,
    bubbleFromUserTextColor: "White",
    microphoneButtonColorOnDictate: "#F33",
    sendBoxBackground: "White",
    sendBoxButtonColor: "#006298",
    sendBoxButtonColorOnDisabled: "#F8FAFB",
    sendBoxButtonColorOnFocus: "#006298",
    sendBoxButtonColorOnHover: "#118AC7",
    sendBoxDisabledTextColor: "#98A8B8",
    sendBoxHeight: 44,
    sendBoxMaxHeight: 200,
    sendBoxTextColor: "#192129",
    transcriptTerminatorFontSize: 8,
    timestampFormat: "relative",
    groupTimestamp: 180000,
    sendBoxBorderBottom: undefined,
    sendBoxBorderTop: "solid 2px #E3E3E4",
    sendBoxTextWrap: true,
    sendBoxButtonAlignment: "stretch",
  };

  useEffect(() => {
    const conversationId = localStorage?.getItem("conversationId");
    if (conversationId) {
      const reconnect = async () => {
        try {
          const response = await fetch(
            `https://directline.botframework.com/v3/directline/conversations/${conversationId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_AZUREBOT_TOKEN}`,
              },
            }
          );
          const data = await response.json();
          console.log(data);
          setDirectline(
            createDirectLine({
              token: data.token,
              streamUrl: data.streamUrl,
            })
          );

          localStorage?.setItem("conversationId", conversationId);
          localStorage?.setItem("token", data.token);
        } catch (error) {
          console.error("Error reconnecting to conversation:", error);
        }
      };

      reconnect();
    } else {
      setDirectline(
        createDirectLine({
          token: process.env.REACT_APP_AZUREBOT_TOKEN,
        })
      );
    }
  }, []);

  const setLocalStorage = (id) => {
    localStorage?.setItem("conversationId", id);
  };

  const attachmentMiddleware = () => (next) => (card) => {
    setLocalStorage(card.activity.conversation.id);
    if (card.activity.from.role === "bot") {
      return (
        <CustomCard card={card} next={next} handleChipClick={sendMessage} />
      );
    }
    return next(card);
  };

  const avatarMiddleware = () => (next) => (card) => {
    const { fromUser, ...props } = card;
    const { MessageType, AgentFirstName, AgentLastName } =
      props.activity.channelData || {};

    return () => {
      if (fromUser) {
        if (props.activity.from.name) {
          return (
            <Avatar style={defaultAvatarStyles}>
              {getInitialsFromFirstNameOrFullNameAndLastName(
                props.activity.from.name
              )}
            </Avatar>
          );
        }
        return (
          <img
            src={guestAvatarURL}
            alt="Bot Avatar"
            style={defaultAvatarStyles}
          />
        );
      }

      if (MessageType === "Agent" || card.activity.from.role === "bot") {
        if (AgentFirstName) {
          return (
            <Avatar style={defaultAvatarStyles}>
              {AgentLastName
                ? getInitialsFromFirstNameOrFullNameAndLastName(
                    AgentFirstName,
                    AgentLastName
                  )
                : getInitialsFromFirstNameOrFullNameAndLastName(AgentFirstName)}
            </Avatar>
          );
        }

        return (
          <img
            src={hopeChatBotAvatarURL}
            alt="Hope Avatar"
            style={defaultAvatarStyles}
          />
        );
      }

      return next(card)();
    };
  };

  const groupActivitiesMiddleware =
    () =>
    () =>
    (next) =>
    ({ activities }) => {
      // Ensure no messages are grouped; TODO: Modify grouping logic
      const sender = activities.map((activity) => [activity]);
      const status = activities.map((activity) => [activity]);

      return {
        sender,
        status,
      };
    };

  const showCloseModalWindow = () => {
    setShowCloseModal(true);
  };
  const closeChat = () => {
    directLine
      .postActivity({
        from: { id: "user1", name: "user" },
        type: "message",
        text: "[closed_chat_window]",

        textFormat: "plain",
      })
      .subscribe(() => {
        window.parent?.postMessage({ type: "CHAT_CLOSED" }, "*");
        window.android?.closeBot();
        window.webkit?.messageHandlers?.closeBot?.postMessage(null);
        localStorage?.removeItem("conversationId");
        setIsChatClosed(true);
      });
  };

  const onMinimizeClick = () => {
    if (!isMinimized) {
      window.parent?.postMessage({ type: "CHAT_MINIMIZED" }, "*");
      window.android?.minimizeBot();
      window.webkit?.messageHandlers?.minimizeBot?.postMessage(null);
    } else {
      window.parent?.postMessage({ type: "CHAT_MAXIMIZED" }, "*");
      window.android?.maximizeBot();
      window.webkit?.messageHandlers?.maximizeBot?.postMessage(null);
    }
    setIsMinimized(!isMinimized);
  };

  const minimizeChat = () => {
    return (
      <IconButton
        aria-label="minimize chatbox"
        className={classes.iconButton}
        onClick={onMinimizeClick}
      >
        {!isMinimized ? (
          <CloseFullscreen />
        ) : (
          <OpenInFull size={16} className={classes.infoIcon} />
        )}
      </IconButton>
    );
  };

  const getHeader = () => {
    return (
      <Paper square className={classes.chatHeader}>
        <div className={classes.accent}></div>

        <div className={classes.headerFlexContainer}>
          <Avatar
            sx={{
              height: 48,

              width: 48,

              border: "2px solid #DAE2EA",
            }}
            alt="Remy Sharp"
            src="assets/images/Hope_Agent_avatar_40x40.svg"
          />
          <div className={classes.headerText}>
            <Typography variant="body2">Hope</Typography>
            <Typography variant="caption">Virtual Assistant</Typography>
          </div>
          <div className={classes.headerIcons}>
            {!isMinimized && menuDropdown()}
            {minimizeChat()}
            <IconButton
              className={classes.iconButton}
              aria-label="close chatbox"
              onClick={showCloseModalWindow}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
        </div>
      </Paper>
    );
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const selectItem = (item) => {
    sendMessage(item);
    closeMenu();
  };

  const sendMessage = (message) => {
    const data = {
      from: { id: "user1", name: "user" },
      type: "message",
      textFormat: "plain",
    };
    if (message.data.linkSelected) {
      data.text = message.data.linkSelected;
    } else {
      data.value = message.data;
    }
    directLine.postActivity(data).subscribe(
      (id) => {
        console.log(`message sent with id ${id}`);
      },
      (error) => console.log(error)
    );
  };

  const menuDropdown = () => {
    const dropdownItems = [
      {
        data: { action: "live_agent" },
        title: "Chat with a Care Advocate",
        type: "Action.Submit",
      },
      {
        data: { action: "technical_support" },
        title: "Help",
        type: "Action.Submit",
      },
    ];
    return (
      <IconButton>
        <MenuIcon
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={openMenu}
          color="#006298"
          size={16}
          className={classes.menuIcon}
        />
        <Menu
          className={classes.menu}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          elevation={0}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              boxShadow:
                "0px 5px 3px 0px rgb(25 33 41 / 4%), 0px 8px 5px 0px rgb(25 33 41 / 8%)",
            },
          }}
        >
          {dropdownItems.map((item) => (
            <MenuItem
              className={classes.menuItem}
              onClick={() => selectItem(item)}
            >
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      </IconButton>
    );
  };
  const closeModal = () => {
    setShowCloseModal(false);
  };

  return !isChatClosed ? (
    <div className={classes.chatContainer}>
      {getHeader()}

      <div className={classes.disclaimer}>
        <p className={classes.disclaimerItem}>
          You are currently testing the enhanced version of our chatbot. Thank
          you for allowing us to learn from your interaction.
        </p>
        <p className={`${classes.disclaimerItem} ${classes.emergencyBanner}`}>
          If this is a medical emergency, call 911 immediately.
        </p>
      </div>

      {directLine && (
        <div className={classes.chatBody}>
          <ReactWebChat
            directLine={directLine}
            styleOptions={styleOptions}
            userID="YOUR_USER_ID"
            attachmentMiddleware={attachmentMiddleware}
            avatarMiddleware={avatarMiddleware}
            groupActivitiesMiddleware={groupActivitiesMiddleware}
            overrideLocalizedStrings={{
              TEXT_INPUT_PLACEHOLDER: "Send a message...",
            }}
          />
        </div>
      )}
      {showCloseModal && (
        <div className={classes.closeModal}>
          <div className={classes.closeModalItem}>End Chat?</div>
          <div className={`${classes.closeModalItem} ${classes.grow}`}>
            Are you sure you'd like to end your current chat session?
          </div>
          <div className={classes.actions}>
            <CustomButton variant="outlined" onClick={closeModal}>
              No, Continue Chat
            </CustomButton>
            <CustomButton variant="contained" onClick={closeChat}>
              Yes, End Chat
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  ) : null;
}
