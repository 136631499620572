import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { CustomButton } from "./customButton";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    display: "flex",
    flexDirection: "column",

    "& .MuiInputBase-root": {
      marginBottom: 16,
      height: 44,
    },
    "& .MuiInputBase-input": {
      padding: "16px 12px",
    },
  },
  textBlock: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 600,
  },
  actionButton: {
    marginBottom: "8px !important",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
}));
const getVariant = (text) => {
  switch (text) {
    case "Confirm":
    case "Continue":
    case "Verify":
      return "contained";
    case "Resend":
    case "Skip":
      return "outlined";
    default:
      return "text";
  }
};

export function CareAdvocateChat({ content, handleClick }) {
  const classes = useStyles();
  const [formValues, setformValues] = useState({});

  const confirmDropdown = (element) => {
    element.data = { ...element.data, ...formValues };
    handleClick(element);
  };

  const handleTextFieldChange = (element, event) => {
    setformValues((prevForm) => ({
      ...prevForm,
      [element.id]: event.target.value,
    }));
  };

  return (
    <div className={classes.root}>
      {content.body.map((element) => {
        return (
          <>
            <div className={classes.textBlock}>{element.label}</div>
            <TextField
              id="outlined-basic"
              variant="outlined"
              onChange={(event) => handleTextFieldChange(element, event)}
            />
          </>
        );
      })}
      {content?.actions.map((element) => {
        return (
          <CustomButton
            onClick={() => confirmDropdown(element)}
            variant={getVariant(element.title)}
            element={element}
            className={classes.actionButton}
          ></CustomButton>
        );
      })}
    </div>
  );
}

CareAdvocateChat.propTypes = {
  content: PropTypes.object,
};
