import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, TextField } from "@mui/material";
import { CustomButton } from "./customButton";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    "& .MuiButtonBase-root": {
      width: "100%",
      height: 44,
      borderRadius: "100px",
      fontSize: 14,
      fontWeight: 600,
      textTransform: "none !important",
      fontFamily: "Montserrat",
    },
    "& .MuiTextField-root": {
      height: 44,
      width: "100%",
    },
    "& .MuiInputBase-root": {
      height: 44,
    },
    "& .MuiInputBase-input": {
      padding: "16px 12px",
      width: "100%",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    paddingTop: 16,
  },
  outlined: {
    color: "#006298 !important",
    border: "1px solid var(--Neutral-Gray---100, #DAE2EA) !important",
  },
  contained: {
    background: "#006298 !important",
  },
  text: {
    color: "#006298 !important",
  },
  body: {
    fontSize: 12,
    lineHeight: "150%",
    letterSpacing: 0.11,
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
}));

export function RefillConfirmation({ content, handleClick }) {
  const classes = useStyles();
  const [confirmed, setConfirmed] = useState(false);

  const confirmDropdown = (element) => {
    if (textFieldValue) {
      element.data["comment"] = textFieldValue;
    }
    setConfirmed(true);
    handleClick(element);
  };
  const getVariant = (text) => {
    switch (text) {
      case "Confirm Details":
        return "contained";
      case "Cancel":
        return "text";
      default:
        return "outlined";
    }
  };
  const getElementPayload = (content) => {
    const body = {};
    {
      content.body.map((element) => {
        switch (element.type) {
          case "FactSet": {
            body["facts"] = element.facts;
            break;
          }

          case "Input.Text": {
            body["comments"] = element;
            break;
          }

          case "Container": {
            element.items.map((element) => {
              return (body["pharmacy"] = element.facts[0]);
            });
            break;
          }

          case "ActionSet": {
            body["actions"] = element.actions;
            break;
          }
        }
      });
    }
    return body;
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const body = getElementPayload(content);
  const [textFieldValue, setTextFieldValue] = useState(body.comments.value);
  return (
    <div className={classes.root}>
      <div className={classes.body}>
        {body.facts.map((fact) => {
          return (
            <div>
              <strong>{fact.title}</strong> {fact.value}
            </div>
          );
        })}
        <div>
          <strong>{body.comments.label}</strong>
          {!confirmed ? (
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={textFieldValue}
              placeholder={body.comments.placeholder}
              onChange={handleTextFieldChange}
            />
          ) : (
            textFieldValue
          )}
        </div>
        <div>
          <strong>{body.pharmacy.title}</strong> {body.pharmacy.value}
        </div>
      </div>
      {!confirmed ? (
        <div className={classes.actions}>
          {body.actions.map((action) => {
            return (
              <CustomButton
                onClick={() => confirmDropdown(action)}
                variant={getVariant(action.title)}
                className={classes[getVariant(action.title)]}
                element={action}
              ></CustomButton>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
